import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  max-width: 700px;
`;

function createMarkup(data) { return {__html: data}};

function convertHTMLEntity(text){
  const span = document.createElement('span');

  return text
  .replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
      span.innerHTML = entity;
      return span.innerText;
  });
}

const Preview = ({data}) => {
  const newData = convertHTMLEntity(data.replace(/&lt;style.*style&gt;/, ''));
  return (
    <StyledWrapper>
      <div dangerouslySetInnerHTML={createMarkup(newData)} />
    </StyledWrapper>
  )
}

export default Preview;