import React, {useState} from 'react';
import styled from 'styled-components';

const StyledButton = styled.div`
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dce4e6;
  padding: .5rem 1rem;
  cursor: pointer;
  transition: .1s ease;
  background-color: #ff7f7f;
  &:hover {
    background-color: #ff3333;
  }
`;

const DeleteButton = ({onClick}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      {showConfirm ?
        <StyledButton onClick={() => onClick()}>
          Confirm Delete
        </StyledButton>
        :
        <StyledButton onClick={() => setShowConfirm(true)}>
          Delete
        </StyledButton>
      }
    </>
  );
};

export default DeleteButton;