import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Home from 'pages/Home';
import Navbar from 'components/Navbar';
import Json from 'pages/Json';

const App = () => {
  return (
    <Router>
      <Navbar/>
      <Switch>
        <Route exact path="/" render={props => <Home {...props}/>}/>
        <Route exact path="/json" render={props => <Json {...props}/>}/>
      </Switch>
    </Router>
  );
}

export default App;
