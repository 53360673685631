import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

import {
  convertToRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
} from 'draft-js';

const LinkEditorExample = ({initialContent, name, value, testFunc}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ])));
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const editorRef = useRef(null);
  const [editorFocused, setEditorFocused] = useState(false);
  const focus = () => {
    editorRef.current.focus();
  }
  const onChange = (editorState) => {
    setEditorState(editorState);
    const content  = editorState.getCurrentContent();
    testFunc(convertToRaw(content));
  }
  useEffect(() => {
    const rawEditorData = initialContent;
    if (rawEditorData) {
      const contentState = convertFromRaw(rawEditorData);
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, []);
  useEffect(() => {
    if (value === '') {
      setEditorState(EditorState.createEmpty(new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
      ])))
    }
    else if (value && (!editorFocused)) {
      const contentState = convertFromRaw(value);
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [value])

  const promptForLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setShowURLInput(true);
      setUrlValue(url);
    }
  }

  const confirmLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: urlValue}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setEditorState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    ));
    setShowURLInput(false);
    setUrlValue('');
  }
  const onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      confirmLink(e);
    }
  }

  const removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  }

  return (
    <div style={styles.root}>
      {showURLInput &&
        <div style={styles.urlInputContainer}>
          Enter URL Here
          <input
            onChange={(e) => setUrlValue(e.target.value)}
            ref="url"
            style={styles.urlInput}
            type="text"
            value={urlValue}
            onKeyDown={onLinkInputKeyDown}
          />
          <button onMouseDown={confirmLink}>
            Confirm
          </button>
        </div>}
      <div style={styles.editor} onClick={focus}>
        <Editor
          editorState={editorState}
          onChange={(e) => onChange(e)}
          ref={editorRef}
          name={name}
          onFocus={() => setEditorFocused(true)}
          onBlur={() => setEditorFocused(false)}
        />
      </div>
      <div style={styles.buttons}>
        <button
          onMouseDown={promptForLink}
          style={{marginRight: 10}}>
          Add Link
        </button>
        <button onMouseDown={removeLink}>
          Remove Link
        </button>
      </div>
    </div>
  )
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}
const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <>
      <a href={url} style={styles.link}>
        {props.children}
      </a>
    </>
  );
};
const styles = {
  root: {
    fontSize: 12.8,
  },
  buttons: {
    marginBottom: 3,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    marginRight: 10,
    padding: 3,
  },
  editor: {
    cursor: 'text',
    minHeight: 100,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '.5rem',
    boxSizing: 'border-box',
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};

export default LinkEditorExample;