import React, {useState} from 'react';
import Form from 'components/BigForm';
import Output from 'components/Output';
import Preview from 'components/Preview';
import styled from 'styled-components';
import TextArea from 'components/TextArea';

const StyledWrapper = styled.div`
  display: flex;
  background: #ddd;
  @media only screen and (max-width: 768px) {
    flex-direction: column
  }
`;
const StyledContainer = styled.div`
  width: 50%;
  flex: 1;
  height: calc(100vh - 32px);
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &:nth-child(1) {
    align-items: flex-end;
    border-right: 1px solid #ccc;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const StyledObject = styled.div`
  max-width: 600px;
  width: 100%;
`;
const StyledPreview = styled(StyledContainer)`
  background-color: #fff;
`;

const Home = () => {
  const [postData, setPostData] = useState([]);
  const [html, setHtml] = useState('');
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledObject>
          <Form
            postData={postData}
            setPostData = {setPostData}
          />
        </StyledObject>
        <StyledObject>
          <TextArea data={html}/>
          <Output postData={postData} setHtml={setHtml}/>
        </StyledObject>
      </StyledContainer>
      <StyledPreview>
        <Preview data={html}/>
      </StyledPreview>
    </StyledWrapper>
  );
}

export default Home;
