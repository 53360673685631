import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  max-width: 700px;
`;

function convertHTMLEntity(text){
  const span = document.createElement('span');

  return text
  .replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
      span.innerHTML = entity;
      return span.innerText;
  });
}

const Preview = ({data}) => {
  const newData = convertHTMLEntity(data.replace(/&lt;style.*style&gt;/, ''));
  const textAreaStyle = {
    width: '100%',
  }
  return (
    <StyledWrapper>
      <textarea value={newData} rows="20" style={textAreaStyle} readOnly/>
    </StyledWrapper>
  )
}

export default Preview;