import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  a {
    margin-right: 1rem;
  }
`;
const Navbar = () => {
  return (
    <StyledWrapper>
      <Link to="/">
        Home
      </Link>
      <Link to="/json">
        Json
      </Link>
    </StyledWrapper>
  )
}

export default Navbar;