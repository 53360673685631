import React, {useRef, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import {isEmpty, reduce, insertAll, join, pipe, reverse, sortBy, compose, prop} from 'ramda';

import {CopyToClipboard} from 'react-copy-to-clipboard';

const StyledCodeWrapper =styled.pre`
  background-color: #eee;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 1px;
  overflow: hidden;
  opacity: 0;
`;

const convertBodyToHtml = (value, entityMap) => {
  let newLine = value.text;
  const entityRanges = value.entityRanges || [];
  let items = [];
  entityRanges.forEach(item => {
    items.push({index: item.offset, string: `<a href="${entityMap[item.key].data.url}" target="_blank" rel="noopener noreferrer">` })
    items.push({index: item.offset+item.length, string: '</a>' })
  })
  const insertLinkTags = (newLine, items) => pipe(
    reverse,
    reduce((a, {index, string}) => insertAll(index, string, a), newLine),
    join('')
  )(items)
  return items.length > 0 ? insertLinkTags(newLine, items) : value.text;
}

var htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: '\''
};

function unescapeHTML(str) {
  return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
          return htmlEntities[entityCode];
          /*eslint no-cond-assign: 0*/
      } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
          return String.fromCharCode(parseInt(match[1], 16));
          /*eslint no-cond-assign: 0*/
      } else if (match = entityCode.match(/^#(\d+)$/)) {
          return String.fromCharCode(~~match[1]);
      } else {
          return entity;
      }
  });
};

const StyledCopy = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dce4e6;
  height: 4rem;
  cursor: pointer;
  background-color: #eaf2f4;
  transition: .1s ease;
  &:hover {
    background-color: #b8d3da;
  }
`;

const sorter = sortBy(compose(parseInt, prop('position')));

const Output = ({postData, setHtml}) => {
  const testRef = useRef(null);
  const orderedPosts = useMemo(() => postData && (postData.length > 0) && sorter(postData));
  useEffect(() => {
    setHtml(testRef.current.innerHTML);
  }, [orderedPosts]);
  return (
    <>
    <CopyToClipboard text={testRef.current ? unescapeHTML(testRef.current.innerHTML): ''}>
      <StyledCopy>Copy to clipboard</StyledCopy>
    </CopyToClipboard>
    <StyledCodeWrapper ref={testRef}>

    {orderedPosts && orderedPosts.length > 0 && orderedPosts.map((value, key) => ('abstract' in value) && (
      <>
        {`
          <p class="abstract">
            ${value.abstract}
          </p>
        `}
      </>
    ))}


      {`<section class="object_navigation">`}
        {`<input type="checkbox" name="collapse" id="handle1">`}
        {` <div class="object_navigation__handle">
            <label for="handle1">Content</label>
          </div>
        `}
        {`<div class="content">`}
          {` <div class="object_navigation__title">
              Content
            </div>
          `}
          {`<ul>`}
            {orderedPosts && orderedPosts.length > 0 && sorter(orderedPosts).map((value, key) => !('abstract' in value) && (
              <React.Fragment key={key}>
                {`<li><a href="#${key}">
                  ${key + 1}
                  -
                  ${value.title}
                </a></li>`}
              </React.Fragment>
            ))}
          {`</ul>`}
        {`</div>`}
      {`</section>`}

      {/*
        <section class="accordion">
          <input type="checkbox" name="collapse" id="handle1" checked="checked">
          <h2 class="handle">
            <label for="handle1">26A. Trappist Single</label>
          </h2>
          <div class="content">
            <p><strong>Overall Impression:</strong> A pale, bitter, highly attenuated and well carbonated Trappist ale, showing a fruity-spicy Trappist yeast character, a spicy-floral hop profile, and a soft, supportive grainy-sweet malt palate.</p>
            <p><strong>History:</strong> While Trappist breweries have a tradition of brewing a lower-strength beer as a monk’s daily ration, the bitter, pale beer this style describes is a relatively modern invention reflecting current tastes. Westvleteren first brewed theirs in 1999, but replaced older lower-gravity products.</p>
          </div>
        </section>
      */}
      {orderedPosts && orderedPosts.length > 0 ? orderedPosts.map((value, key) => !('abstract' in value) && (
        <React.Fragment key={key}>
          {`<div class="object__wrapper" id=${key}>`}
          {value.image &&
            <>
              {`
              <a href="${value.purchaseLink}" target="_blank" rel="noopener noreferrer">
                <img src="${value.image}" class="object__image" alt=${value.imageAlt || value.title} />
              </a>
              `}
            </>
          }

          {value.title &&
            <>
              {`
                <div class="object__header">
                  <h3>${value.title}</h3>
                  ${value.purchaseLink && `
                    <a href="${value.purchaseLink}" class="btn-primary" target="_blank" rel="noopener noreferrer">
                      Purchase
                    </a>`}
                </div>
              `}
            </>
          }

          {(!isEmpty(value.keyFeatures[0]) || !isEmpty(value.specifications[0].label) || value.stars) &&
            <>
              {`<div class="object__list_wrapper">`}

                {!isEmpty(value.keyFeatures[0]) && `
                  <div class="object__list_container">
                    <div class="object__list_title left">Key Features</div>
                    `}

                      {!isEmpty(value.keyFeatures[0]) && value.keyFeatures.map((string, index) => (
                        <React.Fragment key={index}>
                          {`<div class="object__features_row">
                            ${string}
                          </div>`}
                        </React.Fragment>
                      ))}
                    {!isEmpty(value.keyFeatures[0]) && `
                  </div>
                `}

                {!isEmpty(value.specifications[0].label) && `
                  <div class="object__list_container">
                    <div class="object__list_title">Specifications</div>
                    `}
                    {value.specifications.map((value, index) => (
                      <React.Fragment key={index}>
                        {`
                          <div class="object__specifications_row">
                            <div>${value.label}</div><div>${value.text}</div>
                          </div>
                        `}
                      </React.Fragment>
                      ))}
                      {!isEmpty(value.specifications[0]) && `
                    </div>
                `}

              {`<div class="object__list_container">`}
                {value.stars && (value.stars.length > 0) ?
                    <>
                      {`<div class="object__list_title stars">EDC Rating</div>
                          <div class="object__stars_wrapper">
                            <div class="object__stars_container">
                              <div class="object__stars_gray"></div>
                              <div class="object__stars_filled" style="width: ${value.stars * 13.6}px"></div>
                            </div>
                          </div>
                          <div>
                          `}
                            {value.reviewCount && (value.reviewCount !== 0) ?
                              `<a href=${value.purchaseLink} target="_blank" rel="noopener noreferrer">
                                ${value.reviewCount} ratings
                              </a>`
                            :
                              `Just released`
                            }
                      {`
                          </div>
                      `}
                    </>
                    :
                    <>
                      {`
                        <div class="object__list_title stars">EDC Rating</div>
                        <div>
                          Just released
                        </div>
                      `}
                    </>
                }

                {value.price > 0 &&
                `<div class="object__price_wrapper">
                  <div class="object__price_title">
                    Price
                  </div>
                  <div class="object__price_price">
                `}
                {value.price > 0 && Array.apply(null, {length: value.price}).map((e, i) => (
                  <span key={i}>$</span>
                ))}
                {value.price > 0 && `
                  </div>
                </div>`}
            </>
          }
          {`</div>`}

          {`</div>`}
            {value.body.blocks && value.body.blocks.map(rawBody => (
              <div key={rawBody.text}>
                {`<div class="object__body">
                  ${convertBodyToHtml(rawBody, value.body.entityMap ? value.body.entityMap : [])}
                </div>`}
              </div>
            ))}

            {value.purchaseLink && `
              <div>
                <a class="btn-primary" href="${value.purchaseLink}" target="_blank" rel="noopener noreferrer">
                  Check It Out
                </a>
              </div>
            `}

{`</div>`}
        </React.Fragment>
      )) : ''}
    </StyledCodeWrapper>
    </>
  );
};

export default Output;