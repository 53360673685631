import React, { useState } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import MyEditor from 'components/MyEditor';
import styled from 'styled-components';
import { Persist } from 'formik-persist';
import Updater from 'components/Updater';
import DeleteButton from 'components/DeleteButton';

const StyledSubmit = styled.button`

`;

const StyledWrapper = styled.div`
  input, textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: .8rem;
    border: 1px solid #ccc;
    padding: .5rem;
  }
  button {
    background-color: #eaf2f4;
    border: 1px solid #dce4e6;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: .1s ease;
    &:hover {
      background-color: #b8d3da;
    }
  }
  ${StyledSubmit} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    cursor: pointer;
    transition: .1s ease;
    font-size: 1rem;
    margin-bottom: 1rem;
    background-color: #186081;
    color: #fff;
    margin-top: 1rem;
    border: none;
    &:hover {
      background-color: #0c3041;
    }
  }
`;
const StyledContainer = styled.div`
  background-color: #eaf6f5;
  border-radius: .5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
`;
const StyledLabel = styled.label`
  font-size: .8rem;
  margin-bottom: 3px;
  text-transform: capitalize;
`;
const StyledRow = styled.div`
  display: flex;
  margin-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  &:first-child {
    padding-top: 1rem;;
  }
  &:nth-last-child(2) {
    padding-bottom: 1rem;
  }
`;
const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px .5rem;
  box-sizing: border-box;
  margin-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
const StyledRowMini = styled.div`
  display: flex;
  width: 100%;
`;
const StyledColumn = styled.div`
  width: 50%;
  padding: 0px .5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;
const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dce4e6;
  height: 4rem;
  cursor: pointer;
  background-color: #eaf2f4;
  transition: .1s ease;
  &:hover {
    background-color: #b8d3da;
  }
`;
const StyledAdd = styled(StyledButton)`
  border-radius: .5rem;
  border-top: none;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
`;
const StyledAddAbstract = styled(StyledAdd)`
  width: auto;
  height: auto;
  display: inline-block;
  padding: .5rem 1rem;
  margin-bottom: 2rem;
`;
const StyledReset = styled(StyledButton)`
  border-radius: .5rem;
  border-top: none;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
  background-color: #ff7f7f;
  margin-bottom: 1rem;
  &:hover {
    background-color: #ff3333;
  }
`;

const StyledWarningWrapper = styled.div`
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWarning = styled.div`
  width: 500px;
`;


function download(filename, text) {
  var element = document.createElement('a');
  const parsed = JSON.stringify(text);
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(parsed));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


const StyledImport = styled.div`
  display: flex;
  input[type="file"] {
    display: none;
  }
  label {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    cursor: pointer;
    transition: .1s ease;
    font-size: 1rem;
    background-color: #186081;
    color: #fff;
    border: none;
    &:hover {
      background-color: #0c3041;
    }
  }
  ${StyledSubmit} {
    box-sizing: border-box;
    width: 50%;
    margin: 0 0 0 .5rem;
    padding: 0;
  }
  > * {
    height: 100%;
    flex: 1;
  }
  margin-bottom: 1rem;
`;

const StyledAbstractRow = styled(StyledRow)`
  label {
    width: 100%;
  }
  textarea {
    resize: none;
  }
  flex-direction: column;
  align-items: flex-start;
  background-color: #eaf6f5;
  border-radius: .5rem;
  padding: 1rem;

`;

const BigForm = ({ postData, setPostData }) => {
  const [warning, setWarning] = useState(false);

  return (
    <StyledWrapper>
      <Formik
        initialValues={{
          posts: [
            {
              abstract: ''
            },
            {
              position: 1,
              title: '',
              image: '',
              imageAlt: '',
              purchaseLink: '',
              body: '',
              keyFeatures: [
                '',
              ],
              specifications: [
                { label: 'Brand', text: '' },
                { label: 'Model', text: '' },
              ],
              stars: 0,
              reviewCount: 0,
              price: 0,
            },
          ]
        }}
        onSubmit={values => setPostData(values.posts)}
        render={({ values, setFieldValue, handleSubmit, handleReset, setValues }) => (
          <Form>

            <StyledImport>
              <label htmlFor="inputfile">
                Import
              </label>
              <input type="file" id="inputfile" onChange={(e) => {
                try {
                  const reader = new FileReader();
                  reader.onload = async (e) => {
                    const text = (e.target.result);
                    setValues(JSON.parse(text));
                  };
                  reader.readAsText(e.target.files[0]);
                }
                catch (e) {
                  console.log(e);
                }
              }} />
              <StyledSubmit onClick={() => download('export.txt', values)}>
                Export
              </StyledSubmit>

            </StyledImport>
            <Updater
              values={values}
              handleSubmit={handleSubmit}
            />
            <StyledReset onClick={() => setWarning(true)}>
              Reset Form
            </StyledReset>
            {warning &&
              <StyledWarningWrapper>
                <StyledWarning>
                  do you want to reset all posts?
                  <StyledReset onClick={() => { handleReset(); setWarning(false); }}>
                    Reset
                  </StyledReset>
                  <StyledReset onClick={() => setWarning(false)}>
                    Cancel
                  </StyledReset>
                </StyledWarning>
              </StyledWarningWrapper>}

            <FieldArray
              name="posts"
              render={arrayHelpers => (
                <div>

                  <StyledAddAbstract
                    onClick={() => arrayHelpers.unshift({
                      abstract: '',
                    })}
                  >
                    Add Abstract
                  </StyledAddAbstract>

                  <Persist name="edc-form" />
                  {values.posts.map((friend, index) => (values.posts[index] && ('abstract' in values.posts[index])) ?
                    <StyledAbstractRow key={index}>
                      <StyledLabel>
                        Abstract (Introduction)
                        <Field component="textarea" name={`posts[${index}].abstract`} rows="8" />
                      </StyledLabel>
                      <DeleteButton onClick={() => arrayHelpers.remove(index)}>
                        Delete
                      </DeleteButton>
                    </StyledAbstractRow>
                    :
                    (
                      <StyledContainer key={index}>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              Position (order, put # here, ex: 1, 2, 3, 4)
                              <Field name={`posts[${index}].position`} />
                            </StyledLabel>
                          </StyledColumn>
                        </StyledRow>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              title
                              <Field name={`posts[${index}].title`} />
                            </StyledLabel>
                          </StyledColumn>
                          <StyledColumn>
                            <StyledLabel>
                              image
                              <Field name={`posts.${index}.image`} />
                            </StyledLabel>
                          </StyledColumn>
                        </StyledRow>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              image alt text
                              <Field name={`posts[${index}].imageAlt`} />
                            </StyledLabel>
                          </StyledColumn>
                        </StyledRow>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              purchase link
                              <Field name={`posts[${index}].purchaseLink`} />
                            </StyledLabel>
                          </StyledColumn>

                        </StyledRow>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              stars
                              <Field component="select" name={`posts[${index}].stars`}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                              </Field>
                            </StyledLabel>
                          </StyledColumn>
                          <StyledColumn>
                            <StyledLabel>
                              review count
                              <Field type="number" name={`posts[${index}].reviewCount`} />
                            </StyledLabel>
                          </StyledColumn>
                        </StyledRow>

                        <StyledRow>
                          <StyledColumn>
                            <StyledLabel>
                              price
                              <Field component="select" name={`posts[${index}].price`} >
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                              </Field>
                            </StyledLabel>
                          </StyledColumn>
                        </StyledRow>

                        <StyledBodyWrapper>
                          <StyledLabel>
                            body
                          </StyledLabel>
                          {postData[index] &&
                            <MyEditor
                              name={`posts.${index}.body`}
                              //initialContent='<div>asdf</div>'
                              initialContent={postData[index] ? postData[index].body : null}
                              testFunc={(value) => {
                                setFieldValue(`posts.${index}.body`, value);
                              }
                              }
                              value={postData[index].body}
                            />
                          }
                        </StyledBodyWrapper>

                        <FieldArray
                          name={`posts[${index}].keyFeatures`}
                          render={arrayHelpers2 => (
                            <StyledRow>
                              <StyledColumn>
                                <StyledLabel>
                                  key features
                                  {values.posts[index].keyFeatures.map((feature, index2) => (
                                    <StyledRowMini key={index2}>
                                      <Field
                                        name={`posts.${index}.keyFeatures[${index2}]`}

                                      />
                                      <button type="button" onClick={() => arrayHelpers2.remove(index2)}>
                                        -
                                      </button>
                                    </StyledRowMini>
                                  ))}
                                </StyledLabel>
                              </StyledColumn>
                              <StyledColumn>
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers2.push('')}

                                >
                                  +
                                </button>
                              </StyledColumn>
                            </StyledRow>
                          )}
                        />

                        <FieldArray
                          name={`posts[${index}].specifications`}
                          render={arrayHelpers4 => (
                            <StyledRow>
                              <StyledColumn>
                                <StyledLabel>
                                  specifications
                                  {values.posts[index].specifications.map((friend, index2) => (
                                    <StyledRowMini key={index2}>
                                      <Field
                                        name={`posts.${index}.specifications[${index2}].label`}

                                      />
                                      <Field
                                        name={`posts.${index}.specifications[${index2}].text`}

                                      />
                                      <button type="button" onClick={() => arrayHelpers4.remove(index2)}>
                                        -
                                      </button>
                                    </StyledRowMini>
                                  ))}
                                </StyledLabel>
                              </StyledColumn>
                              <StyledColumn>
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers4.push({ label: '', text: '' })}

                                >
                                  +
                                </button>
                              </StyledColumn>
                            </StyledRow>
                          )}
                        />

                        <DeleteButton onClick={() => arrayHelpers.remove(index)}>
                          Delete
                        </DeleteButton>
                      </StyledContainer>
                    ))}

                  <StyledAdd
                    onClick={() => arrayHelpers.push({
                      title: '',
                      image: '',
                      imageAlt: '',
                      purchaseLink: '',
                      body: '',
                      keyFeatures: [
                        '',
                      ],
                      specifications: [
                        { label: 'Brand', text: '' },
                        { label: 'Model', text: '' },
                      ],
                      stars: 0,
                      reviewCount: 0,
                      price: 0,
                    })}
                  >
                    Add Section
                  </StyledAdd>
                </div>
              )}
            />
            <StyledSubmit type="submit">
              Submit
            </StyledSubmit>
          </Form>
        )}
      />
    </StyledWrapper>
  );
};

export default BigForm;