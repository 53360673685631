import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Formik, Form, Field} from 'formik';
import Updater from 'components/Updater';
import he from 'he';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';

const convertHtml = (str) =>{
  if (str) {
    return he.encode(str)
  }
  return str;
}

const StyledWrapper = styled.div`
  height: calc(100vh - 32px);
  width: 100%;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    flex-direction: column
  }
`;
const StyledContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 6rem;
  box-sizing: border-box;
  input {
    margin-bottom: 6px;
  }
  form {
    display: flex;
    flex: 1;
    width: 100%;
    @media only screen and (max-width: 992px) {
      flex-direction: column
    }
  }
  font-size: 14px;
  background-color: #e9e9e9;
`;
const StyledContainerSection = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const StyledSectionLabel = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: 12px;
`;
const StyledHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y:auto;
`;
const StyledPre = styled.pre`
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  textarea {
    opacity: 0;
    pointer-events: none;
  }
  padding-bottom: 6rem;
  box-sizing: border-box;
  background-color: #efefef;
`;
const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledCopy = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 4rem;
  border-top: 1px solid #dce4e6;
  cursor: pointer;
  background-color: #b8d3da;
  transition: .1s ease;
  &:hover {
    background-color: #96bec9;
  }
`;
const Json = () => {
  const [formData, setFormData] = useState('');
  const outputRef = useRef(null);
  function copyToClipboard() {
    const copyText = outputRef.current.textContent;
    const textArea = document.createElement('textarea');
    textArea.textContent = copyText;
    outputRef.current.append(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove()
  };

  return (
    <StyledWrapper>
      <StyledContainer>
      <Formik
        initialValues={{
          '@context': 'http://schema.org',
          '@type': 'Review',
          'itemReviewed': {
            '@type': 'Product',
            'name': '',
            'material': '',
            'description': '',
            'image': '',
          },
          'dateCreated': new Date(),
          'dateModified': new Date(),
          'reviewRating' : {
            '@type': 'Rating',
            'ratingValue': '',
            'bestRating': '5',
            'worstRating': '0',
          },
          'author' : {
            '@type': 'Person',
            'name': 'Mikey Bautista',
          },
          'publisher': {
            '@type': 'Organization',
            'name' : 'Everyday Carry',
            'logo': 'https://everydaycarry.com/public/img/logo.png',
          }
        }}
        onSubmit={values => setFormData(values)}
        render={({ values, handleSubmit, setFieldValue}) => (
          <Form>
            <Updater
              values={values}
              handleSubmit={handleSubmit}
            />
            <StyledContainerSection>
              <StyledSectionLabel>general</StyledSectionLabel>
              <StyledLabel>
                @context
                <Field name={`@context`}/>
              </StyledLabel>
              <StyledLabel>
                @type
                <Field name={`@type`}/>
              </StyledLabel>
              <br/>
              <StyledLabel>
                dateCreated

                <DatePicker selected={values.dateCreated} onChange={date => setFieldValue('dateCreated', date)} />
                <button onClick={() => setFieldValue('dateCreated', new Date())}>Today</button>
              </StyledLabel>
              <StyledLabel>
                dateModified

                <DatePicker selected={values.dateModified} onChange={date => setFieldValue('dateModified', date)} />
                <button onClick={() => setFieldValue('dateModified', new Date())}>Today</button>
              </StyledLabel>
              <br/>
              <StyledSectionLabel>itemReviewed</StyledSectionLabel>
              <StyledLabel>
                itemReviewed @type
                <Field name={`itemReviewed.@type`}/>
              </StyledLabel>
              <StyledLabel>
                itemReviewed name
                <Field name={`itemReviewed.name`}/>
              </StyledLabel>
              <StyledLabel>
                itemReviewed material
                <Field name={`itemReviewed.material`}/>
              </StyledLabel>
              <StyledLabel>
                itemReviewed description
                <Field name={`itemReviewed.description`}/>
              </StyledLabel>
              <StyledLabel>
                itemReviewed image
                <Field name={`itemReviewed.image`}/>
              </StyledLabel>
            </StyledContainerSection>
            <StyledContainerSection>
              <StyledSectionLabel>reviewRating</StyledSectionLabel>
              <StyledLabel>
                reviewRating @type
                <Field name={`reviewRating.@type`}/>
              </StyledLabel>
              <StyledLabel>
                reviewRating ratingValue
                <Field name={`reviewRating.ratingValue`}/>
              </StyledLabel>
              <StyledLabel>
                reviewRating bestRating
                <Field name={`reviewRating.bestRating`}/>
              </StyledLabel>
              <StyledLabel>
                reviewRating worstRating
                <Field name={`reviewRating.worstRating`}/>
              </StyledLabel>
              <br/>
              <StyledSectionLabel>author</StyledSectionLabel>
              <StyledLabel>
                author @type
                <Field name={`author.@type`}/>
              </StyledLabel>
              <StyledLabel>
                author name
                <Field component="select" name={`author.name`} >
                  <option value={'Mikey Bautista'}>Mikey Bautista</option>
                  <option value={'Bernard Capulong'}>Bernard Capulong</option>
                  <option value={'Jonathan Tayag'}>Jonathan Tayag</option>
                  <option value={'Mon Garcia'}>Mon Garcia</option>
                  <option value={'Everyday Carry'}>Everyday Carry</option>
                </Field>
              </StyledLabel>
              <br/>
              <StyledSectionLabel>publisher</StyledSectionLabel>
              <StyledLabel>
                publisher @type
                <Field name={`publisher.@type`}/>
              </StyledLabel>
              <StyledLabel>
                publisher name
                <Field name={`publisher.name`}/>
              </StyledLabel>
              <StyledLabel>
                publisher logo
                <Field name={`publisher.logo`}/>
              </StyledLabel>
            </StyledContainerSection>

          </Form>
        )}
      />
      </StyledContainer>
      <StyledHalf>
      <StyledPre ref={outputRef}>
  {`
<script type="application/ld+json">
  {
    "@context": "${convertHtml(formData['@context'], {'decimal': true})}",
    "@type": "${convertHtml(formData['@type'], {'decimal': true})}",
    "itemReviewed": {
      "@type": "${convertHtml(formData.itemReviewed?.['@type'], {'decimal': true})}",
      "name": "${convertHtml(formData.itemReviewed?.['name'], {'decimal': true})}",
      "material": "${convertHtml(formData.itemReviewed?.['material'], {'decimal': true})}",
      "description": "${convertHtml(formData.itemReviewed?.['description'], {'decimal': true})}",
      "image": "${convertHtml(formData.itemReviewed?.['image'], {'decimal': true})}",
      "review": {
        "reviewRating" : {
          "@type": "${convertHtml(formData.reviewRating?.['@type'], {'decimal': true})}",
          "ratingValue": "${convertHtml(formData.reviewRating?.['ratingValue'], {'decimal': true})}",
          "bestRating": "${convertHtml(formData.reviewRating?.['bestRating'], {'decimal': true})}",
          "worstRating": "${convertHtml(formData.reviewRating?.['worstRating'], {'decimal': true})}"
        },
        "author": {
          "@type": "${convertHtml(formData.author?.['@type'], {'decimal': true})}",
          "name": "${convertHtml(formData.author?.['name'], {'decimal': true})}"
        }
      }
    },
    "dateCreated": "${formData['dateCreated'] && format(formData['dateCreated'], 'MMMM dd, yyyy')}",
    "dateModified": "${formData['dateModified'] && format(formData['dateModified'], 'MMMM dd, yyyy')}",
    "reviewRating" : {
      "@type": "${convertHtml(formData.reviewRating?.['@type'], {'decimal': true})}",
      "ratingValue": "${convertHtml(formData.reviewRating?.['ratingValue'], {'decimal': true})}",
      "bestRating": "${convertHtml(formData.reviewRating?.['bestRating'], {'decimal': true})}",
      "worstRating": "${convertHtml(formData.reviewRating?.['worstRating'], {'decimal': true})}"
    },
    "author": {
      "@type": "${convertHtml(formData.author?.['@type'], {'decimal': true})}",
      "name": "${convertHtml(formData.author?.['name'], {'decimal': true})}"
    },
    "publisher": {
      "@type": "${convertHtml(formData.publisher?.['@type'], {'decimal': true})}",
      "name": "${convertHtml(formData.publisher?.['name'], {'decimal': true})}",
      "logo": "${convertHtml(formData.publisher?.['logo'], {'decimal': true})}"
    }
  }
</script>
<!-- Schema End -->
  `}

      </StyledPre>
        <StyledCopy onClick={(e) => copyToClipboard(e)}>Copy to clipboard</StyledCopy>
      </StyledHalf>
    </StyledWrapper>
  )
}

export default Json;